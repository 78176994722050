<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-01-21 09:15:11
 * @LastEditors: hutian
 * @LastEditTime: 2021-01-21 17:34:30
-->
<template>
  <div class="to-audit">
    <div>
      <a-button type="primary" @click="toHandler">审核</a-button>
    </div>
    <!-- 数据表格 -->
        <a-table :loading="tableLoading" :row-selection="rowSelection" :rowKey="record => record.id"
            :columns="columns" :data-source="tableData" bordered :pagination="page"
            @change="changeTable" :customRow="changeTableRow">
            <span slot="flagEnable" slot-scope="item, row">
                <a-switch :checked="row.flagEnable" checked-children="启用" un-checked-children="关闭" default-checked />
            </span>
            <span slot="index" slot-scope="text, row, index">{{index + 1}}</span>
            <span slot="imgUrl" slot-scope="item">
                <ZoomMedia style="height: 20px;" :value="item"></ZoomMedia>
            </span>
            <span slot="flagUp" slot-scope="item">{{item == 1 ? '上架' : item == 2 ? '下架' : '强制下架'}}</span>
            <span slot="action" slot-scope="text, row">
                <a @click="handleEdit(row)">编辑</a>
                <a-divider type="vertical" />
                <a @click="delByIds([row.id])">删除</a>
                <a-divider type="vertical" />
                <a @click="onCheck(row)">查看</a>
            </span>
        </a-table>
        <ToAuditTabel ref="ToAuditTabel"></ToAuditTabel>
  </div>
</template>

<script>
import { columns } from './components/colums.js'
import ToAuditTabel from './components/ToAuditTabel'
import { delProductGoods, listProductGoods, selectByIdProductGoods, listInfoGoods } from './api/ProductGoodsApi'
const pageSource = {
    current: 1,
    pageSize: 10,
    total: 0
}
  export default {
    components: {
        ToAuditTabel
    },
    data() {
      return {
        columns: columns,
        tableData: [],
        searchData: {}, // 搜索条件
        scroll:{ y: 600 },
        tableLoading: false, //表格loading
        page: Object.assign({}, pageSource),
        selectedRowKeys: [],
        selectedRows: []
      }
    },
    computed: {
        rowSelection() {
            const _this = this
            return {
                fixed: true,
                type: 'radio', //"radio"
                selectedRowKeys: this.selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                    _this.selectedRowKeys = selectedRowKeys
                    _this.selectedRows = selectedRows
                }
            }
        }
    },
    methods: {
      callback(key) {
        console.log(key);
      },
      // 查看
      onCheck(item) {
        const _this = this
        _this.$refs.ProductGoodsEditModal.setRowData(item, 'check')
      },
        /**
         * 获取表格数据
         */
        getData() {
            this.tableLoading = true
            listInfoGoods({
                pageNumber: this.page.current,
                pageSize: this.page.pageSize,
                ...this.searchData
            }).then(res => {
                if (res.code === 200) {
                    const { total, records } = res.body
                    this.page.total = total
                    this.tableData = records
                }
            }).finally(() => this.tableLoading = false)
        },
        /**
         * 筛选查询
         */
        getDataFilter() {
            this.page = Object.assign({}, pageSource)
            this.getData()
        },
        /**
         * 重置
         */
        reset() {
            this.searchData = {}
            this.page = Object.assign({}, pageSource)
            this.getData()
        },
        /**
         * 更改分页
         */
        changeTable(pagination) {
            this.page = pagination
            this.getData()
        },
        /**
         * 点击行选中
         */
        changeTableRow(record) {
            return {
                on: {
                    click: e => {
                        if (this.selectedRowKeys[0] === record.id) {
                            this.selectedRowKeys = []
                            this.selectedRows = []
                        } else {
                            this.selectedRowKeys = [record.id]
                            this.selectedRows = [record]
                        }
                    }
                }
            }
        },
        // 编辑
        handleEdit(row) {
            const _this = this
            _this.$refs.ProductGoodsEditModal.setRowData(row, 'edit')
        },
        // 删除
        delByIds(id) {
            const _this = this
            _this.$confirm({
                title: '删除警告',
                content: '确认要删除此条记录吗?',
                okText: '确认',
                cancelText: '取消',
                async onOk() {
                    const res = await delProductGoods(_this.selectedRows[0].id)
                    if (res.code === 200) {
                        _this.$notification.success({ message: res.message })
                        _this.getData()
                    } else {
                        _this.$notification.error({ message: res.message })
                    }
                },
                onCancel() {}
            })
        },
        toHandler() {
            const _this = this
            if (_this.selectedRows.length === 0) {
                _this.$notification.info({
                    message: '请选择一条记录'
                })
                return
            }
            _this.$refs.ToAuditTabel.isShow(_this.selectedRows[0])
        },
    },
    created() {
        this.getData()
    }
  }
</script>

<style lang="scss">
  
</style>
